* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}
main {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: rgb(0, 0, 0);
  color: white;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}


main {
  padding: 10px;
  background: #fff;
  width: 1200px;
  margin: 0 auto;
  color: rgb(0, 0, 0);
}

@media (max-width:767px) {
  .main-container{
    width: 100%;
    left: 0;
  
  }
  
  
    main {
      padding: 10px 0;
      width: 95%;
      margin-left: 5%;
      margin: 0 auto;
      color: rgb(0, 0, 0);
    }
    
    
  }

  .petroleum-price-list {
    padding: 20px;
    background-color: #f7f7f7;
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .add-product-section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .input-section,
  .location-section {
    margin-bottom: 20px;
    width: 30%;
  
  }
  
  .location-item {
    margin-bottom: 15px;
  
  }
  
  .depot-section {
    margin-left: 20px;
    margin-top: 10px;
  }
  
  .depot-label {
    display: inline-block;
    margin-right: 10px;
  }
  
  .add-product-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-product-btn:hover {
    background-color: #0056b3;
  }
  
  .product-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .product-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    width: 45%;
  }
  
  .supplier-section {
    margin-top: 10px;
  }
  
  .upload-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .upload-overlay p {
    font-size: 16px;
    font-weight: bold;
  }
  
  .location-list{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid green;
  }
  
  .add-product-button{
    width: 80%;
    display: block;
    padding: 10px;
    text-align: center;
    margin: 0 auto;
    background: green;
    border-radius: 20px;
  }
  
  .bg{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  
  @media (max-width:767px) {
    .product-item {
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      width: 300px;
    }
    
  }

  
  .my-masonry-grid {
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
  }

  .my-masonry-grid_column {
    padding-left: 30px;
    /* gutter size */
    background-clip: padding-box;
  }

  .upload-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    color: white;
    flex-direction: column;
    font-size: 1.2rem;
  }

  .my-masonry-grid {
    padding: 10px;
  }

  .my-masonry-grid_column {
    padding-left: 10px;
    /* gutter size */
    background-clip: padding-box;
  }

  .media-item img,
  .media-item video {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 30px;
  }
.header{
  background: #0fb300;
  width: 100%;
  color: black;
  padding: 20px;
}


  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 14, 14, 0.711);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    /* Ensure it overlays above other content */
  }


  .media-gallery-component {
    width: 100%;
    overflow-x: hidden;
  }



.blog-btn {
  display: flex;
  justify-content: space-around;

}

.blog-btn button {
  font-size: 1rem;
}

.p {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

  
.blog-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin: 0;
}

.card {
  width: 30%;
  display: flex;
  padding: 10px;
  margin: 2%;
  background: #ffffff;
  box-shadow: 2px 2px 2px 2px black;


}

.card-img {
  width: 100%;
  flex: 1;
}

.card img {
  width: 100%;

}



#quill-editor {
  min-height: 15em;
  /* Adjust the value as needed */
}

.card-bodi {
  flex: 5;
}

.editor {
  height: 200px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: black;
  border-radius: 4px;
}


.price-list {
  flex: 1;
  margin: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  height: auto;
}

.blog-div {
  flex: 2;
  margin: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-height: 800px;
  overflow-y: auto;
}

.login-container {
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 100px auto;
  color: black;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.btn2 {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

.login-container h1 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.login-container .form-group {
  margin-bottom: 15px;
  display: block;
}

.login-container .form-group input {
  margin-bottom: 15px;
  width: 90%;
  display: block;
  background: transparent;
  border: 2px solid black;
  border-radius: 15px;
  padding: 10px;
  color: black;
}




.petroleum-price-list {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-left: 15px;
}

.content-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.add-product-section {
  flex: 1;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-product-section h3 {
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #076914;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0fb300;
}

.product-container {
  flex: 2;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
}

.product-item {
  margin-bottom: 20px;
  font-size: 1rem;
}

.product-item h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.supplier-section {
  margin-top: 10px;

}
.supplier-div{
  display: flex;

  width: 100%;
  justify-content: space-around;
}
.supplier-div-input{
  width: 100px;
}
.supplier-div-button{
  width: 200px;
}
.upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 1000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content-container {
      flex-direction: column;
  }

  .add-product-section,
  .product-container {
      width: 100%;
  }
}
